import React, { Suspense } from "react";

import classNames from "classnames";

import Loading from "components/Loading";

const LazySQLEditor = React.lazy(() => import("./index.lazy"));

const SQLEditor = ({
  sql,
  onChange,
  placeholder,
  className,
}: {
  sql: string;
  onChange: (sql: string) => void;
  placeholder?: string;
  className?: string;
}) => {
  return (
    <Suspense fallback={<Loading />}>
      <LazySQLEditor
        sql={sql}
        placeholder={placeholder}
        onChange={onChange}
        className={classNames(
          "rounded border border-[#E8E8EE] text-[#606060] font-query text-[13px]",
          className,
        )}
      />
    </Suspense>
  );
};

export default SQLEditor;
